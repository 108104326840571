module.exports = [{
      plugin: require('/home/mayo/Projects/takasi/manual-company-manager/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/home/mayo/Projects/takasi/manual-company-manager/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"enableCustomId":true,"className":"table-of-content"},
    },{
      plugin: require('/home/mayo/Projects/takasi/manual-company-manager/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/mayo/Projects/takasi/manual-company-manager/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/mayo/Projects/takasi/manual-company-manager/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
